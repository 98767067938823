.ribbon_element{

}

.chapter{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: visible;

}